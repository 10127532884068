import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Projects from './pages/Projects';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Link from './components/Square';

function App() {
  return (
    <div>
      <Home links = {
          [

          ]}/>
      <Projects/>
    </div>
  );
}

export default App;
