class Project
{
    constructor(name, url, desc, key)
    {
        this.name = name;
        this.url = url;
        this.desc = desc;
        this.key = key;
        this.spanStyle = 
        {
            fontSize: "13px",
            marginLeft: "30px",
            fontFamily:"Roboto",
            fontWeight: "100",
        }
    }

    render()
    {
        return <div className={`project slideOut${this.key}s`}>
            <a className="project-item" target="_blank" key = {this.key} href = {this.url}>{this.name} <span style = {this.spanStyle}>{this.desc}</span></a>
        </div>
    }
}

export default Project;