import Project from "./Project.js"
import React from "react";

function Link(props)
{
        let clicked = props.clicked;
        let text = props.text;
        let key = props.keyNo;
        let currPage = "Home";

        function removeProject(out)
        {
            if (out)
            {
                for (let i = 0; i < document.querySelector("#project-container").children.length; i++)
                    {
                        document.querySelector("#project-container").children[i].classList.remove(`slideOut${i + 1}s`);
                        document.querySelector("#project-container").children[i].classList.add(`slideIn${i + 1}s`);
                    }
                    document.querySelector("#project-square").classList.remove("slideOut1s");
                    document.querySelector("#about-me").classList.remove("slideOut1s");                        
                    document.querySelector("#project-square").classList.add("slideIn1s");
                    document.querySelector("#about-me").classList.add("slideIn1s");
            }
            else
            {
                for (let i = 0; i < document.querySelector("#project-container").children.length; i++)
                    {
                        document.querySelector("#project-container").children[i].classList.remove(`slideIn${i + 1}s`);
                        document.querySelector("#project-container").children[i].classList.add(`slideOut${i + 1}s`);
                    }
            }
        }

        function slideHero()
        {

        }

        return (<div style = {
            {
                display: "flex", 
                alignItems: "center",
            }
        } className = "link" id = {text} key = {key} onClick={(e) => 
            {


            }}>
            <div style = {
                {
                    position: "absolute",
                    width: "8px",
                    paddingBottom: "0px",
                    height: "8px",
                    marginLeft: "-10px",
                    // marginTop: "5px",
                    backgroundColor: "#B33032",
                    transition: "0.5s ease",
                    display: clicked ? "block" : "none",

                    // animation: "renderIn 1s forwards"
                }
            }></div>
            <a onClick=
            {
                (e) =>
                    {
                if (e?.target?.parentElement?.parentElement?.children)
                    {
                        for (let child of e?.target?.parentElement?.parentElement?.children)
                        {
                            if (child.tagName == "DIV")
                            {



                                if (child?.id == e?.target?.parentElement?.id)
                                {
                                    child.firstChild.style.display = "flex";
                                    // child.lastChild.style.color = "gainsboro";
                                }
                                else
                                {
                                    child.firstChild.style.display = "none";
                                    // child.lastChild.style.color = "#110E0B";
                                }
                            }
                        }
                    }
                // Here
            

                if (key == 1)
                    {
                    let nav = document.querySelector("nav");
                        
                                for (let text of nav.children)
                                {
                                    text.style.color = "black";
                                }
        

                        if (document.querySelector(".three").style.display == "flex")
                            {
                                removeProject(true);
                                setTimeout(() =>
                                    {
                                        document.querySelector(".three").style.display = "none";
                                        document.querySelector(".four").style.display = "none";
                                        document.querySelector(".five").style.display = "none";
                                        document.querySelector(".six").style.display = "none";
                                        for (let i = 0; i < document.querySelector(".two").children.length; i++)
                                        {
                                            document.querySelector(".two").children[i].classList.remove(`slideIn${i + 1}s`);
                                            document.querySelector(".two").children[i].classList.add(`slideOut${i + 1}s`);
                                        }
                                        removeProject();
                                        document.querySelector(".two").style.display = "grid";
                
                                    }, 1000)
                            }
                            
                        if (document.querySelector(".four").style.display == "flex")
                            {
                            document.querySelector(".four").classList.remove("slideToRight");
                            document.querySelector(".five").children[0].classList.remove("slideUp");
                            document.querySelector(".six").classList.remove("slideUp");
                            document.querySelector(".four").classList.add("slideToLeft");
                            document.querySelector(".five").children[0].classList.add("slideDown");
                            document.querySelector(".six").classList.add("slideDown");
                            document.body.style.backgroundColor = "white";
                            // setTimeout(
                            // () => 
                            // {
                            // },1000)

                            setTimeout(
                            () =>
                            {
                                document.querySelector(".three").style.display = "none";
                                document.querySelector(".four").style.display = "none";
                                document.querySelector(".five").style.display = "none";
                                document.querySelector(".six").style.display = "none";
                                for (let i = 0; i < document.querySelector(".two").children.length; i++)
                                {
                                    document.querySelector(".two").children[i].classList.remove(`slideIn${i + 1}s`);
                                    document.querySelector(".two").children[i].classList.add(`slideOut${i + 1}s`);
                                }
                                document.querySelector(".five").children[0].classList.remove(`slideDown`);
                                document.querySelector(".four").classList.remove(`slideToLeft`);
                                document.querySelector(".six").classList.remove(`slideDown`);
                                document.querySelector(".five").children[0].classList.add(`slideUp`);
                                document.querySelector(".four").classList.add(`slideToRight`);
                                document.querySelector(".six").classList.add(`slideUp`);
                                document.querySelector(".two").style.display = "grid";

                            }, 600)
                            }


                }
                else if (key == 2)
                {
                    // document.querySelector(".two").style.display = "grid";
                    let nav = document.querySelector("nav");

                            for (let text of nav.children)
                            {
                                text.style.color = "black";
                            }
    

                    if (!(document.querySelector(".two").style.display == "none"))
                    {
                        for (let i  = 0; i < document.querySelector(".two").children.length; i++)
                        {
                            document.querySelector(".two").children[i].classList.remove(`slideOut${i + 1}s`);
                            document.querySelector(".two").children[i].classList.add(`slideIn${i + 1}s`);
                        }

                    }

                    if (document.querySelector(".four").style.display == "flex")
                        {
                        document.querySelector(".four").classList.remove("slideToRight");
                        document.querySelector(".five").children[0].classList.remove("slideUp");
                        document.querySelector(".six").classList.remove("slideUp");
                        document.querySelector(".four").classList.add("slideToLeft");
                        document.querySelector(".five").children[0].classList.add("slideDown");
                        document.querySelector(".six").classList.add("slideDown");
                        document.body.style.backgroundColor = "white";
                        // setTimeout(
                        // () => 
                        // {
                        // },1000)

                        setTimeout(
                        () =>
                        {
                            document.querySelector(".three").style.display = "none";
                            document.querySelector(".four").style.display = "none";
                            document.querySelector(".five").style.display = "none";
                            document.querySelector(".six").style.display = "none";

    
                        }, 600)
                        }

                    setTimeout(() =>
                    {
                        document.querySelector(".two").style.display = "none";
                        document.querySelector(".four").style.display = "none";
                        document.querySelector(".five").style.display = "none";
                        document.querySelector(".six").style.display = "none";
                        document.querySelector(".five").children[0].classList.remove(`slideDown`);
                        document.querySelector(".four").classList.remove(`slideToLeft`);
                        document.querySelector(".six").classList.remove(`slideDown`);
                        document.querySelector(".five").children[0].classList.add(`slideUp`);
                        document.querySelector(".four").classList.add(`slideToRight`);
                        document.querySelector(".six").classList.add(`slideUp`);
                        document.querySelector(".three").style.display = "flex";
                        document.querySelector("#project-square").classList.add("slideOut1s");
                        document.querySelector("#about-me").classList.add("slideOut1s");

                    }, 1000)
                }
                else if (key == 3)
                {

                    let nav = document.querySelector("nav");
                    setTimeout(() =>
                    {
                        for (let text of nav.children)
                        {
                            text.style.color = "white";
                        }

                    }, 1000)

                    if (!(document.querySelector(".two").style.display == "none"))
                    {
                        for (let i  = 0; i < document.querySelector(".two").children.length; i++)
                            {
                                document.querySelector(".two").children[i].classList.remove(`slideOut${i + 1}s`);
                                document.querySelector(".two").children[i].classList.add(`slideIn${i + 1}s`);
                            }

                    }
                    else if (!(document.querySelector(".three").style.display == "none"))
                    {
                        removeProject(true);
                        setTimeout(() =>
                            {

                                document.querySelector(".three").style.display = "none";
                                document.querySelector(".four").style.display = "none";
                                document.querySelector(".five").style.display = "none";
                                document.querySelector(".six").style.display = "none";

                                removeProject();
                            }, 2000)
                    }
                    setTimeout(() => {
                        document.querySelector(".two").style.display = "none";
                        document.body.style.transition = "1s ease";
                        document.body.style.backgroundColor = "black";
                    }, 1000)
                    
                    setTimeout(() => {
                        document.querySelector(".five").style.display = "block";
                        document.querySelector(".six").style.display = "block";
                        document.querySelector(".three").style.display = "none";
                        document.querySelector(".five").children[0].classList.add(`slideUp`);
                        document.querySelector(".four").classList.add(`slideToRight`);
                        document.querySelector(".four").style.display = "flex";
                        document.querySelector(".four").style.color = "white";
                        document.querySelector(".six").style.backgroundColor="white";
                        document.querySelector(".six").classList.add(`slideUp`);
                    }, 2000)
                    
                    setTimeout(()=>{
                    },3000);


                }
                else if (key == 4)
                {



                }
                    }
            }>{text}</a>
        </div>
        )

}

export default Link;