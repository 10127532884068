import "../styles/Home.css"
import {useEffect, useState} from "react"
import Link from "../components/Square.js"
import Project from "../components/Project.js"

function Home(props)
{

    let [currLink, setCurrLink] = useState("Home");

    let projects =
    [
        new Project("3D GAME ENGINE", "https://github.com/cothao/OPENGL_Engine/tree/version3.0", "May.2024 / Personal / Portfolio / Graphics Development", 1).render(),
        new Project("PIRANHA ATTACK", "https://cothao.itch.io/piranha-attack", "May.2024 / Personal / Game Jam / Game Development", 2).render(),
        new Project("Aqua Game Jam", "https://github.com/cothao/Aqua_Game_Jam", "May.2023 / Personal / Passion Project / Game Development", 3).render(),
        new Project("Residency App", "https://github.com/cothao/myGalaxy/tree/master", "June.2024 / Personal / Passion Project / Mobile FullStack Development", 4).render(),

        // new Project("PROJECT NAOMI", "", "Mo.202X / Commission / Special / Type", 7).render(),
    ];

    props.links[0] = <Link clicked = {true} text = {"Home"} keyNo = {1} links = {props.links}/>;
    props.links[1] = <Link clicked = {false} text = {"Projects"} keyNo = {2} links = {props.links}/>;
    props.links[2] = <Link clicked = {false} text = {"Contact Me"} keyNo = {3} links = {props.links}/>;
    // props.links[3] = <Link clicked = {false} text = {"FAQ"} keyNo = {4} links = {props.links} currPage = "Home"/>;


    return (
        <div id = "home-section">
            <div id = "main">
            <div className="one">
                <nav>
                    <h1>Collin Xuezheng Thao</h1> 
                    <h2>Developer</h2>     
                    {props.links}
                </nav>
            </div>
            <div className="two">
                <div className="slideOut1s">THE</div>
                <div className="slideOut2s">PINNACLE</div>
                <div className="slideOut3s">OF</div>
                <div className="slideOut4s">PROGRAMMING</div>
                {/* <div className="slideOut5s">FASHION</div> */}
            </div>
            <div className="three">
                <div id = "projects-section">
                <div id = "project-square">
                <div className="red-square"></div>
                <h4>Projects</h4>
                </div>
                <div id = "project-container">

                {projects}
                </div>
                </div>
                <p id = "about-me">Born in 2001 in Milwaukee, WI. I believe web design and development can be more diverse and inspiring. With a mission to be present with possibilities of web design. I pursue new experiences through experiments and thoughts. You can find my most recent work here.</p>
            </div>
            <div className="four">
                <div>
                    <h1>Contact Info</h1>
                    <div id = "Info-grid">
                        <div className="grid-one">
                            <p>email</p>
                            <p>phone</p>
                        </div>
                        <div className="grid-two">
                            <p>collinthao685@gmail.com</p>
                            <p>+1.262.230.7297</p>
                        </div>
                        <div className="grid-three">
                            <p>github</p>
                            <p>itchio</p>
                            <p>linkedin</p>
                            <p>shadertoy</p>
                        </div>
                        <div className="grid-four">
                            <p id = "github" onClick={() => window.open("https://github.com/cothao", "_blank").focus()}>https://github.com/cothao</p>
                            <p id = "itchio" onClick={() => window.open("https://cothao.itch.io/", "_blank").focus()}>https://cothao.itch.io/</p>
                            <p id = "linkedin" onClick={() => window.open("https://www.linkedin.com/in/collin-thao/", "_blank").focus()}>https://www.linkedin.com/in/collin-thao/</p>
                            <p id = "shader" onClick={() => window.open("https://www.shadertoy.com/profile/?show=shaders", "_blank").focus()}>https://www.shadertoy.com/profile/?show=shaders</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="five">
                <h1>HERO</h1>
                <div></div>
            </div>
            <div className="six"></div>
            </div>
        </div>
    )
}

export default Home;